import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import Logo from "./logo";
import { FormattedMessage } from "react-intl";
import { commaSeperatedCurrency } from "../../infrastructure/helpers";
import { isMobileOnly } from "react-device-detect";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import sponsore from "../../assets/sponsored.png";
import first from "../../assets/first.png";
import bouns from "../../assets/bouns.png";
import offer from "../../assets/offered.png";
import preferred from "../../assets/Preferred.png";
import _ from "lodash";
import LoanOptions from "./loan-option";
import StickyHeader from "../../components/sticky-header";

const ApplicantResults = ({
  application: consumerApplication = {},
  lenderCollection,
}) => {
  const [lenders, setLenders] = useState({});
  const [loanOptions, setLoanOptions] = useState([]);
  const clickApply = async (item) => {
    window.open(item.lenderLink, "_blank");
  };

  useEffect(() => {
    const lenderDictionary = lenderCollection.reduce((accumulator, current) => {
      accumulator[current.lenderId] = current;
      return accumulator;
    }, {});
    setLenders(lenderDictionary);
  }, [lenderCollection]);

  useEffect(() => {
    if (
      !consumerApplication?.prequalificationCollection ||
      consumerApplication.prequalificationCollection.length === 0
    ) {
      setLoanOptions([]);
      return;
    }
    const arr = [];
    consumerApplication.prequalificationCollection.forEach((l) => {
      const options = l.loanOptionCollection;
      if (Array.isArray(options) && options.length > 0) {
        options.forEach((k) => {
          const item = {
            lenderId: l.lenderId,
            lenderName: l.lenderName,
            link: `https://www.${l.lenderName
              .replace(/\s+/, "")
              .toLowerCase()}.com`,
            logoUrl: lenders[l.lenderId]?.logo?.url || "",
            loanType: options.find((i) => !!i.loanType)?.loanType || "–",
            maxFinancingAmount: l.approvedAmount,
            approvedAmount: l.approvedAmount,
            minMonthlyPayment: _.get(k, "monthlyPayment", undefined),
            monthlyPayment: _.get(k, "monthlyPayment", undefined),
            minInterestRate: _.get(k, "interestRate", undefined),
            interestRate: _.get(k, "interestRate", undefined),
            privacyPolicyLink: k?.privacyPolicyUrl || null,
            score: Math.abs(_.get(k, "score", 0)),
            redirectLink: k.link,
            numberOfInstallment: k.numberOfInstallment,
          };
          arr.push(item);
        });
      }
    });
    setLoanOptions([...arr]);
  }, [consumerApplication, lenders]);

  return (
    <div className={`${styles["results-container"]}`}>
      <header className='header_patient'>
        <div className={`nav container ${styles["header-nav"]}`}>
          <Logo isLeft />
          <div className='title'>
            {consumerApplication.applicantInfo?.firstName}
            {"  "}
            {consumerApplication.applicantInfo.lastName}
          </div>
        </div>
      </header>

      <div className='main main_patient'>
        <div className='main_patient_box'>
          <div className='bg_white'>
            <div className={styles["applicant-container"]}>
              <StickyHeader initalOffset={0} disabled>
                <div className={styles["applicant-header"]}>
                  <Col
                    className={`color_gray ${styles["header-title"]}`}
                    xs={6}
                    md={2}
                  >
                    <FormattedMessage id='Amount' />:
                  </Col>
                  <Col
                    className={`fw_bold ${styles["header-value"]}`}
                    xs={6}
                    md={1}
                  >
                    {consumerApplication.amount
                      ? commaSeperatedCurrency(consumerApplication.amount, 0)
                      : "–"}
                  </Col>
                  <div className={styles["line-break"]} />
                  <Col
                    className={`color_gray ${styles["header-title"]}`}
                    xs={6}
                    md={2}
                  >
                    <FormattedMessage id='TreatmentType' />:
                  </Col>
                  <Col
                    className={`fw_bold ${styles["header-value"]}`}
                    xs={6}
                    md={2}
                  >
                    {!!consumerApplication.treatmentType && (
                      <FormattedMessage
                        id={`TreatmentType_${consumerApplication.treatmentType.toUpperCase()}`}
                      />
                    )}
                  </Col>
                  <div className={styles["line-break"]} />
                  <Col
                    className={`color_gray ${styles["header-title"]}`}
                    xs={6}
                    md={4}
                  >
                    <FormattedMessage id='preferredMonthlyPayment' />:
                  </Col>
                  <Col
                    className={`fw_bold ${styles["header-value"]}`}
                    xs={6}
                    md={1}
                  >
                    {consumerApplication.applicantInfo.preferredMonthlyPayment
                      ? commaSeperatedCurrency(
                          consumerApplication.applicantInfo
                            .preferredMonthlyPayment,
                          0
                        )
                      : "–"}
                  </Col>
                </div>
              </StickyHeader>
              <div className='d_flex'>
                <a
                  target='_blank'
                  className={`link_primary ms_auto mb_3 ${styles["link-ads"]}`}
                  href='https://www.dentirate.com/advertiser-disclosure'
                >
                  <FormattedMessage id='AdvertisementDisclosure' />{" "}
                  <FontAwesomeIcon icon={faExternalLink} />
                </a>
              </div>
            </div>
          </div>
          <div className='container'>
            {consumerApplication.prequalificationCollection && (
              <>
                <div className=''>
                  {consumerApplication.prequalificationCollection.length > 1 ? (
                    <div className='t_center fs_lg'>
                      <div
                        className={`${styles["congratulations"]} fs_gt_2 fw_bold`}
                      >
                        <FormattedMessage id='Congratulation' />
                      </div>
                      <div className='mt_2 fw_medium'>
                        <FormattedMessage id='We got you pre-approved with' />{" "}
                        <span className='fs_lg color_primary fw_bold'>
                          {loanOptions.length}{" "}
                        </span>
                        <FormattedMessage id='lenders' />.
                      </div>
                    </div>
                  ) : (
                    <div className='t_center fs_lg'>
                      <div className='fs_gt_2 fw_bold me_1_sm d_inline_sm'>
                        {" "}
                        <FormattedMessage id='Congratulation' />
                      </div>
                      {/* <FormattedMessage id="Andhara bank" /> */}
                      <div className='mt_2 fw_medium mt_0_sm d_inline_sm'>
                        <FormattedMessage id='We got you pre-approved with' />{" "}
                        <span className='fs_lg color_primary fw_bold'>
                          {(
                            consumerApplication.prequalificationCollection[0]
                              .lenderName || ""
                          ).trim()}
                          .
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <LoanOptions prequalicationCollection={loanOptions} />
              </>
            )}
          </div>
        </div>
        {/* <PageFooter /> */}
      </div>
    </div>
  );
};

ApplicantResults.propTypes = {};

export default ApplicantResults;
