/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo } from "react";
import HomeChart from "./homeChart/homeChart.js";
import { getItem, getUserInfo } from "../../utils/storage.js";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { sendPostByJson, sendGetKeyValue } from "../../utils/httpUtils.js";
import Loading from "../Loading/loading.js";
import PropTypes from "prop-types";
import apiLoadingStatus from "../../infrastructure/constants/api/api-loading-status.js";
import { useCommonContext } from "../../common-context/common-context-provider.js";
import _ from "lodash";
import commonContextActions from "../../common-context/common-context-actions.js";
import moment from "moment";

const HomeData = ({
  overviewCount = {},
  loadingStatus = apiLoadingStatus.unloaded,
  dataFilter = {
    viewType: "WTD",
  },
  onChangeFilter = () => {},
  userType = "Lender",
}) => {
  const [imageUrl, setDataList] = useState(null);
  const [{ user, logo }, commonDispatch] = useCommonContext();

  const setLogo = async () => {
    if (
      !!logo.url &&
      !!logo.organizationId &&
      !!user.data?.user?.organizationId &&
      logo.organizationId === user.data?.user?.organizationId
    ) {
      return;
    }
    const userType = JSON.parse(getItem("role"));
    const organizationId = user.data?.user?.organizationId || "";
    const url = "/Organization/Logos";
    try {
      let data = {
        requestCollection: [
          {
            organizationId: organizationId,
            organizationType: userType == "Lender" ? 3 : 2,
          },
        ],
      };
      const res = await sendPostByJson(url, data);
      if (res.status == 200) {
        const url = _.get(
          res.data,
          "data.logoCollection[0].logoCollection[0].imageCdnUrl",
          null
        );
        if (!!url) {
          commonDispatch({
            type: commonContextActions.updateLogo,
            payload: {
              url,
              organizationId: user.data?.user?.organizationId || null,
            },
          });
        }
      }
    } catch (error) {
      commonDispatch({
        type: commonContextActions.updateLogo,
        payload: {
          url: null,
          organizationId: user.data?.user?.organizationId || null,
        },
      });
    }
  };
  const options = useMemo(() => {
    // if (new Date().getMonth() === 0) {
    //   return [
    //     { label: "WTD", value: "WTD" },
    //     { label: "MTD", value: "MTD" },
    //   ];
    // }
    return [
      { label: "WTD", value: "WTD" },
      { label: "MTD", value: "MTD" },
      { label: "QTD", value: "QTD" },
      { label: "YTD ", value: "YTD" },
    ];
  }, []);
  useEffect(() => {
    if (user.loadingStatus === apiLoadingStatus.loaded) {
      setLogo();
    } else {
      commonDispatch({
        type: commonContextActions.updateLogo,
        payload: {
          url: null,
          organizationId: user.data?.user?.organizationId || null,
        },
      });
    }
  }, [user.loadingStatus]);

  if (user.loadingStatus !== apiLoadingStatus.loaded) {
    return <></>;
  }

  return (
    <>
      {/* <div className='hc_title d_none d_block_md'>HOME</div> */}
      <div></div>
      <div>
        <form className='form_row my_5 my_2_md hc_form'>
          <label className='form_label ms_auto'></label>
          <div className='form_select'>
            <Select
              // defaultValue={[hcgList[2], hcgList[3]]}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "200px",
                }),
              }}
              name='colors'
              options={options}
              value={
                options.find(
                  (i) => i.value === dataFilter?.viewType?.toUpperCase()
                ) || options[0]
              }
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={(e) => {
                onChangeFilter(e.value);
              }}
            />
          </div>
        </form>
        <div className='dash_kpi_date' style={{ margin: "0px 10px " }}>
          {moment().format("MMM DD, yyyy")}
        </div>
      </div>
      <div className='hc_logo'>
        {!!logo.url &&
          !!logo.organizationId &&
          !!user.data?.user?.organizationId &&
          logo.organizationId === user.data?.user?.organizationId && (
            <img
              className='mx_auto d_block'
              style={{ maxWidth: "280px", maxHeight: "140px" }}
              alt=''
              src={logo.url}
            />
          )}
      </div>

      <ul className='home_data hc_data mb_3'>
        <li className='item'>
          <div className='count'>
            {overviewCount?.totalSubmittedCount || "–"}
          </div>
          <div className='name'>Submitted</div>
        </li>
        <li className='item'>
          <div className='count'>
            {overviewCount?.totalPreApprovedCount || "–"}
          </div>
          <div className='name'>Pre-approved</div>
        </li>
        <li className='item'>
          <div className='count'>{overviewCount?.totalLeadCount || "–"}</div>
          <div className='name'>
            {(userType || "").toUpperCase() === "LENDER"
              ? "Leads"
              : "Prospects"}
          </div>
        </li>
      </ul>
    </>
  );
};

HomeData.propTypes = {
  overviewCount: PropTypes.shape({
    lenderId: PropTypes.number,
    totalSubmittedCount: PropTypes.number,
    totalPreApprovedCount: PropTypes.number,
    totalLeadCount: PropTypes.number,
  }),
  loadingStatus: PropTypes.oneOf(Object.values(apiLoadingStatus)),
  dataFilter: PropTypes.shape({
    viewType: PropTypes.string,
  }),
  onChangeFilter: PropTypes.func,
  userType: PropTypes.string,
};
export { HomeData };

const ChartContainer = ({
  trendCollection = [],
  loadingStatus = apiLoadingStatus.unloaded,
  dataFilter = {
    viewType: "WTD",
  },
  userType = "Lender",
}) => {
  if (
    loadingStatus !== apiLoadingStatus.loaded ||
    !Array.isArray(trendCollection)
    // ||
    // trendCollection.length === 0
  ) {
    return <></>;
  }
  return (
    <div className='chart_container hc_chart mt_0_md'>
      <HomeChart
        userType={userType}
        trendCollection={trendCollection}
        viewType={dataFilter?.viewType}
      />
    </div>
  );
};

ChartContainer.propTypes = {
  loadingStatus: PropTypes.oneOf(Object.values(apiLoadingStatus)),
  dataFilter: PropTypes.shape({
    viewType: PropTypes.string,
  }),
  trendCollection: PropTypes.arrayOf(
    PropTypes.shape({
      lenderId: PropTypes.number,
      year: PropTypes.number,
      month: PropTypes.number,
      week: PropTypes.number,
      day: PropTypes.number,
      totalSubmittedCount: PropTypes.number,
      preApprovedCount: PropTypes.number,
      totalLeadCount: PropTypes.number,
    })
  ),
  userType: PropTypes.string,
};
export { ChartContainer };

export function MenuButton() {
  const source = parseInt(getItem("source"));
  const navigate = useNavigate();
  return (
    <div className='home_menu hc_menu'>
      {(source === 200 || source === 201) && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className='item bg_primary'
          onClick={() => {
            navigate("/leads");
          }}
        >
          <div className='name'>Leads</div>

          <img
            className='icon ms_auto'
            src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyNSAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjA2MjEgMi4yMjg3MkMwLjUgMy43OTA4MiAwLjUgNi4zMDQ5OCAwLjUgMTEuMzMzM1YxNi42NjY2QzAuNSAyMS42OTQ5IDAuNSAyNC4yMDkxIDIuMDYyMSAyNS43NzEyQzMuNjI0MTkgMjcuMzMzMyA2LjEzODM1IDI3LjMzMzMgMTEuMTY2NyAyNy4zMzMzSDEzLjgzMzNDMTguODYxNiAyNy4zMzMzIDIxLjM3NTggMjcuMzMzMyAyMi45Mzc5IDI1Ljc3MTJDMjQuNSAyNC4yMDkxIDI0LjUgMjEuNjk0OSAyNC41IDE2LjY2NjZWMTEuMzMzM0MyNC41IDYuMzA0OTggMjQuNSAzLjc5MDgyIDIyLjkzNzkgMi4yMjg3MkMyMS4zNzU4IDAuNjY2NjI2IDE4Ljg2MTYgMC42NjY2MjYgMTMuODMzMyAwLjY2NjYyNkgxMS4xNjY3QzYuMTM4MzUgMC42NjY2MjYgMy42MjQxOSAwLjY2NjYyNiAyLjA2MjEgMi4yMjg3MlpNMTMuNSA1Ljk5OTk2QzEzLjUgNS40NDc2NyAxMy4wNTIzIDQuOTk5OTYgMTIuNSA0Ljk5OTk2QzExLjk0NzcgNC45OTk5NiAxMS41IDUuNDQ3NjcgMTEuNSA1Ljk5OTk2VjcuNjY2NjNIOS44MzMyOUM5LjI4MTAxIDcuNjY2NjMgOC44MzMyOSA4LjExNDM0IDguODMzMjkgOC42NjY2M0M4LjgzMzI5IDkuMjE4OTEgOS4yODEwMSA5LjY2NjYzIDkuODMzMjkgOS42NjY2M0gxMS41TDExLjUgMTEuMzMzM0MxMS41IDExLjg4NTYgMTEuOTQ3NyAxMi4zMzMzIDEyLjUgMTIuMzMzM0MxMy4wNTIzIDEyLjMzMzMgMTMuNSAxMS44ODU2IDEzLjUgMTEuMzMzM0wxMy41IDkuNjY2NjNMMTUuMTY2NiA5LjY2NjYzQzE1LjcxODkgOS42NjY2MyAxNi4xNjY2IDkuMjE4OTEgMTYuMTY2NiA4LjY2NjYzQzE2LjE2NjYgOC4xMTQzNCAxNS43MTg5IDcuNjY2NjMgMTUuMTY2NiA3LjY2NjYzSDEzLjVWNS45OTk5NlpNNy4xNjY2NyAxNS42NjY2QzYuNjE0MzggMTUuNjY2NiA2LjE2NjY3IDE2LjExNDMgNi4xNjY2NyAxNi42NjY2QzYuMTY2NjcgMTcuMjE4OSA2LjYxNDM4IDE3LjY2NjYgNy4xNjY2NyAxNy42NjY2SDE3LjgzMzNDMTguMzg1NiAxNy42NjY2IDE4LjgzMzMgMTcuMjE4OSAxOC44MzMzIDE2LjY2NjZDMTguODMzMyAxNi4xMTQzIDE4LjM4NTYgMTUuNjY2NiAxNy44MzMzIDE1LjY2NjZINy4xNjY2N1pNOC41IDIxQzcuOTQ3NzIgMjEgNy41IDIxLjQ0NzcgNy41IDIyQzcuNSAyMi41NTIyIDcuOTQ3NzIgMjMgOC41IDIzSDE2LjVDMTcuMDUyMyAyMyAxNy41IDIyLjU1MjIgMTcuNSAyMkMxNy41IDIxLjQ0NzcgMTcuMDUyMyAyMSAxNi41IDIxSDguNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo='
            alt=''
          />
        </a>
      )}
      {(source === 1 || source === 100) && (
        <a
          className='item bg_primary'
          onClick={() => navigate("/applications")}
        >
          <div className='name'>Applications</div>
          <img
            className='icon ms_auto'
            src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjIwNjczIDMuMTcxNTdDMy4wMzUxNiA0LjM0MzE1IDMuMDM1MTYgNi4yMjg3NiAzLjAzNTE2IDEwVjE0QzMuMDM1MTYgMTcuNzcxMiAzLjAzNTE2IDE5LjY1NjkgNC4yMDY3MyAyMC44Mjg0QzUuMzc4MyAyMiA3LjI2MzkyIDIyIDExLjAzNTIgMjJIMTMuMDM1MkMxNi44MDY0IDIyIDE4LjY5MiAyMiAxOS44NjM2IDIwLjgyODRDMjEuMDM1MiAxOS42NTY5IDIxLjAzNTIgMTcuNzcxMiAyMS4wMzUyIDE0VjEwQzIxLjAzNTIgNi4yMjg3NiAyMS4wMzUyIDQuMzQzMTUgMTkuODYzNiAzLjE3MTU3QzE4LjY5MiAyIDE2LjgwNjQgMiAxMy4wMzUyIDJIMTEuMDM1MkM3LjI2MzkyIDIgNS4zNzgzIDIgNC4yMDY3MyAzLjE3MTU3Wk0xMi43ODUxIDZDMTIuNzg1MSA1LjU4NTc5IDEyLjQ0OTQgNS4yNSAxMi4wMzUxIDUuMjVDMTEuNjIwOSA1LjI1IDExLjI4NTEgNS41ODU3OSAxMS4yODUxIDZWNy4yNUgxMC4wMzUxQzkuNjIwOTEgNy4yNSA5LjI4NTEyIDcuNTg1NzkgOS4yODUxMiA4QzkuMjg1MTIgOC40MTQyMSA5LjYyMDkxIDguNzUgMTAuMDM1MSA4Ljc1SDExLjI4NTFMMTEuMjg1MSAxMEMxMS4yODUxIDEwLjQxNDIgMTEuNjIwOSAxMC43NSAxMi4wMzUxIDEwLjc1QzEyLjQ0OTQgMTAuNzUgMTIuNzg1MSAxMC40MTQyIDEyLjc4NTEgMTBMMTIuNzg1MSA4Ljc1TDE0LjAzNTEgOC43NUMxNC40NDkzIDguNzUgMTQuNzg1MSA4LjQxNDIxIDE0Ljc4NTEgOEMxNC43ODUxIDcuNTg1NzkgMTQuNDQ5MyA3LjI1IDE0LjAzNTEgNy4yNUgxMi43ODUxVjZaTTguMDM1MTYgMTMuMjVDNy42MjA5NCAxMy4yNSA3LjI4NTE2IDEzLjU4NTggNy4yODUxNiAxNEM3LjI4NTE2IDE0LjQxNDIgNy42MjA5NCAxNC43NSA4LjAzNTE2IDE0Ljc1SDE2LjAzNTJDMTYuNDQ5NCAxNC43NSAxNi43ODUyIDE0LjQxNDIgMTYuNzg1MiAxNEMxNi43ODUyIDEzLjU4NTggMTYuNDQ5NCAxMy4yNSAxNi4wMzUyIDEzLjI1SDguMDM1MTZaTTkuMDM1MTYgMTcuMjVDOC42MjA5NCAxNy4yNSA4LjI4NTE2IDE3LjU4NTggOC4yODUxNiAxOEM4LjI4NTE2IDE4LjQxNDIgOC42MjA5NCAxOC43NSA5LjAzNTE2IDE4Ljc1SDE1LjAzNTJDMTUuNDQ5NCAxOC43NSAxNS43ODUyIDE4LjQxNDIgMTUuNzg1MiAxOEMxNS43ODUyIDE3LjU4NTggMTUuNDQ5NCAxNy4yNSAxNS4wMzUyIDE3LjI1SDkuMDM1MTZaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K'
            alt=''
          />
        </a>
      )}
      {(source === 1 || source === 200 || source === 100) && (
        <a
          className='item bg_primary'
          onClick={() => navigate("/user-management")}
        >
          <div className='name'>User management</div>

          <img
            className='icon ms_auto'
            src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMxIDE1QzMxIDE4Ljg2NiAyNy44NjYgMjIgMjQgMjJDMjAuMTM0IDIyIDE3IDE4Ljg2NiAxNyAxNUMxNyAxMS4xMzQgMjAuMTM0IDggMjQgOEMyNy44NjYgOCAzMSAxMS4xMzQgMzEgMTVaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMzYgMzNDMzYgMzYuODY2IDMwLjYyNzQgNDAgMjQgNDBDMTcuMzcyNiA0MCAxMiAzNi44NjYgMTIgMzNDMTIgMjkuMTM0IDE3LjM3MjYgMjYgMjQgMjZDMzAuNjI3NCAyNiAzNiAyOS4xMzQgMzYgMzNaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMTQuMjQ0MSAxMEMxNC41OTkgMTAgMTQuOTQ1NSAxMC4wMzQ4IDE1LjI4MDEgMTAuMTAxMUMxNC40NjUgMTEuNTQ4OSAxNCAxMy4yMjAyIDE0IDE1QzE0IDE2LjczNjUgMTQuNDQyNiAxOC4zNjk2IDE1LjIyMTIgMTkuNzkyN0MxNC45MDQ5IDE5Ljg1MTcgMTQuNTc4MiAxOS44ODI1IDE0LjI0NDEgMTkuODgyNUMxMS40MTUzIDE5Ljg4MjUgOS4xMjIwNSAxNy42NzAyIDkuMTIyMDUgMTQuOTQxM0M5LjEyMjA1IDEyLjIxMjMgMTEuNDE1MyAxMCAxNC4yNDQxIDEwWiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTEwLjg5NDcgMzcuOTcxOUM5Ljc1ODg0IDM2LjYxNDIgOSAzNC45NDggOSAzM0M5IDMxLjExMTUgOS43MTMxNSAyOS40ODggMTAuNzkxNiAyOC4xNTM1QzYuOTgyMTkgMjguNDQ4OSA0IDMwLjUzMjQgNCAzMy4wNTg4QzQgMzUuNjA4OCA3LjAzNDYxIDM3LjcwNzcgMTAuODk0NyAzNy45NzE5WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTMzLjk5OTggMTVDMzMuOTk5OCAxNi43MzY1IDMzLjU1NzIgMTguMzY5NiAzMi43Nzg2IDE5Ljc5MjdDMzMuMDk0OSAxOS44NTE3IDMzLjQyMTYgMTkuODgyNSAzMy43NTU3IDE5Ljg4MjVDMzYuNTg0NiAxOS44ODI1IDM4Ljg3NzggMTcuNjcwMiAzOC44Nzc4IDE0Ljk0MTNDMzguODc3OCAxMi4yMTIzIDM2LjU4NDYgMTAgMzMuNzU1NyAxMEMzMy40MDA4IDEwIDMzLjA1NDMgMTAuMDM0OCAzMi43MTk3IDEwLjEwMTFDMzMuNTM0OCAxMS41NDg5IDMzLjk5OTggMTMuMjIwMiAzMy45OTk4IDE1WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTM3LjEwNTIgMzcuOTcxOUM0MC45NjUyIDM3LjcwNzcgNDMuOTk5OCAzNS42MDg4IDQzLjk5OTggMzMuMDU4OEM0My45OTk4IDMwLjUzMjQgNDEuMDE3NiAyOC40NDg5IDM3LjIwODMgMjguMTUzNUMzOC4yODY3IDI5LjQ4OCAzOC45OTk4IDMxLjExMTUgMzguOTk5OCAzM0MzOC45OTk4IDM0Ljk0OCAzOC4yNDEgMzYuNjE0MiAzNy4xMDUyIDM3Ljk3MTlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K'
            alt=''
          />
        </a>
      )}
      {(source === 1 || source === 100) && (
        <a className='item bg_primary' onClick={() => navigate("/locations")}>
          <div className='name'>Locations</div>

          <img
            className='icon ms_auto'
            src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCA0QzE1LjE2MzQgNCA4IDEyLjAwNTIgOCAyMUM4IDI5LjkyNDQgMTMuMTA2NiAzOS42MjQ4IDIxLjA3NDEgNDMuMzQ4OUMyMi45MzE1IDQ0LjIxNyAyNS4wNjg1IDQ0LjIxNyAyNi45MjU5IDQzLjM0ODlDMzQuODkzNCAzOS42MjQ4IDQwIDI5LjkyNDMgNDAgMjFDNDAgMTIuMDA1MiAzMi44MzY2IDQgMjQgNFpNMjQgMjRDMjYuMjA5MSAyNCAyOCAyMi4yMDkxIDI4IDIwQzI4IDE3Ljc5MDkgMjYuMjA5MSAxNiAyNCAxNkMyMS43OTA5IDE2IDIwIDE3Ljc5MDkgMjAgMjBDMjAgMjIuMjA5MSAyMS43OTA5IDI0IDI0IDI0WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg=='
            alt=''
          />
        </a>
      )}
      {(source === 1 || source === 100 || source === 200 || source === 201) && (
        <a className='item bg_primary' onClick={() => navigate("/dashboard")}>
          <div className='name'>Dashboard</div>

          <img
            className='icon ms_auto'
            src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQgMTIuNDIxMUM0IDguNDUxMzMgNCA2LjQ2NjQ3IDUuMzE4MDIgNS4yMzMyM0M2LjYzNjA0IDQgOC43NTczNiA0IDEzIDRDMTcuMjQyNiA0IDE5LjM2NCA0IDIwLjY4MiA1LjIzMzIzQzIyIDYuNDY2NDcgMjIgOC40NTEzMyAyMiAxMi40MjExVjM1LjU3OUMyMiAzOS41NDg3IDIyIDQxLjUzMzUgMjAuNjgyIDQyLjc2NjhDMTkuMzY0IDQ0IDE3LjI0MjYgNDQgMTMgNDRDOC43NTczNiA0NCA2LjYzNjA0IDQ0IDUuMzE4MDIgNDIuNzY2OEM0IDQxLjUzMzUgNCAzOS41NDg3IDQgMzUuNTc4OVYxMi40MjExWiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTI2IDMwLjhDMjYgMjYuNjUxNiAyNiAyNC41Nzc1IDI3LjMxOCAyMy4yODg3QzI4LjYzNiAyMiAzMC43NTc0IDIyIDM1IDIyQzM5LjI0MjYgMjIgNDEuMzY0IDIyIDQyLjY4MiAyMy4yODg3QzQ0IDI0LjU3NzUgNDQgMjYuNjUxNiA0NCAzMC44VjM1LjJDNDQgMzkuMzQ4NCA0NCA0MS40MjI1IDQyLjY4MiA0Mi43MTEzQzQxLjM2NCA0NCAzOS4yNDI2IDQ0IDM1IDQ0QzMwLjc1NzQgNDQgMjguNjM2IDQ0IDI3LjMxOCA0Mi43MTEzQzI2IDQxLjQyMjUgMjYgMzkuMzQ4NCAyNiAzNS4yVjMwLjhaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMjYgMTFDMjYgOC44MjU2MSAyNiA3LjczODQxIDI2LjM0MjUgNi44ODA4MUMyNi43OTkzIDUuNzM3MzUgMjcuNjc1MyA0LjgyODg3IDI4Ljc3NzkgNC4zNTUyM0MyOS42MDQ5IDQgMzAuNjUzMyA0IDMyLjc1IDRIMzcuMjVDMzkuMzQ2NyA0IDQwLjM5NTEgNCA0MS4yMjIxIDQuMzU1MjNDNDIuMzI0NyA0LjgyODg3IDQzLjIwMDcgNS43MzczNSA0My42NTc1IDYuODgwODFDNDQgNy43Mzg0MSA0NCA4LjgyNTYxIDQ0IDExQzQ0IDEzLjE3NDQgNDQgMTQuMjYxNiA0My42NTc1IDE1LjExOTJDNDMuMjAwNyAxNi4yNjI3IDQyLjMyNDcgMTcuMTcxMSA0MS4yMjIxIDE3LjY0NDhDNDAuMzk1MSAxOCAzOS4zNDY3IDE4IDM3LjI1IDE4SDMyLjc1QzMwLjY1MzMgMTggMjkuNjA0OSAxOCAyOC43Nzc5IDE3LjY0NDhDMjcuNjc1MyAxNy4xNzExIDI2Ljc5OTMgMTYuMjYyNyAyNi4zNDI1IDE1LjExOTJDMjYgMTQuMjYxNiAyNiAxMy4xNzQ0IDI2IDExWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg=='
            alt=''
          />
        </a>
      )}

      {source === 200 && (
        <a className='item bg_primary' onClick={() => navigate("/billing")}>
          <div className='name'>Billing</div>

          <img
            className='icon ms_auto'
            src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMyAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjA2MjEgNC4yMjg3MkM0LjUgNS43OTA4MiA0LjUgOC4zMDQ5OCA0LjUgMTMuMzMzM1YxOC42NjY2QzQuNSAyMy42OTQ5IDQuNSAyNi4yMDkxIDYuMDYyMSAyNy43NzEyQzcuNjI0MTkgMjkuMzMzMyAxMC4xMzg0IDI5LjMzMzMgMTUuMTY2NyAyOS4zMzMzSDE3LjgzMzNDMjIuODYxNiAyOS4zMzMzIDI1LjM3NTggMjkuMzMzMyAyNi45Mzc5IDI3Ljc3MTJDMjguNSAyNi4yMDkxIDI4LjUgMjMuNjk0OSAyOC41IDE4LjY2NjZWMTMuMzMzM0MyOC41IDguMzA0OTggMjguNSA1Ljc5MDgyIDI2LjkzNzkgNC4yMjg3MkMyNS4zNzU4IDIuNjY2NjMgMjIuODYxNiAyLjY2NjYzIDE3LjgzMzMgMi42NjY2M0gxNS4xNjY3QzEwLjEzODQgMi42NjY2MyA3LjYyNDE5IDIuNjY2NjMgNi4wNjIxIDQuMjI4NzJaTTEwLjE2NjcgMTAuNjY2NkMxMC4xNjY3IDEwLjExNDMgMTAuNjE0NCA5LjY2NjYzIDExLjE2NjcgOS42NjY2M0gyMS44MzMzQzIyLjM4NTYgOS42NjY2MyAyMi44MzMzIDEwLjExNDMgMjIuODMzMyAxMC42NjY2QzIyLjgzMzMgMTEuMjE4OSAyMi4zODU2IDExLjY2NjYgMjEuODMzMyAxMS42NjY2SDExLjE2NjdDMTAuNjE0NCAxMS42NjY2IDEwLjE2NjcgMTEuMjE4OSAxMC4xNjY3IDEwLjY2NjZaTTEwLjE2NjcgMTZDMTAuMTY2NyAxNS40NDc3IDEwLjYxNDQgMTUgMTEuMTY2NyAxNUgyMS44MzMzQzIyLjM4NTYgMTUgMjIuODMzMyAxNS40NDc3IDIyLjgzMzMgMTZDMjIuODMzMyAxNi41NTIyIDIyLjM4NTYgMTcgMjEuODMzMyAxN0gxMS4xNjY3QzEwLjYxNDQgMTcgMTAuMTY2NyAxNi41NTIyIDEwLjE2NjcgMTZaTTExLjE2NjcgMjAuMzMzM0MxMC42MTQ0IDIwLjMzMzMgMTAuMTY2NyAyMC43ODEgMTAuMTY2NyAyMS4zMzMzQzEwLjE2NjcgMjEuODg1NiAxMC42MTQ0IDIyLjMzMzMgMTEuMTY2NyAyMi4zMzMzSDE3LjgzMzNDMTguMzg1NiAyMi4zMzMzIDE4LjgzMzMgMjEuODg1NiAxOC44MzMzIDIxLjMzMzNDMTguODMzMyAyMC43ODEgMTguMzg1NiAyMC4zMzMzIDE3LjgzMzMgMjAuMzMzM0gxMS4xNjY3WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg=='
            alt=''
          />
        </a>
      )}
    </div>
  );
}
