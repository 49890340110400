import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import logo from "../../assets/logo-1.svg";
import ValidationWrapper from "../../components/validation-wrapper";
import { NumericFormat } from "react-number-format";
import { Form, Col, FormControl, Row, Alert, Button } from "react-bootstrap";
import { createValidationObject } from "../../infrastructure/helpers/object-helpers";
import _ from "lodash";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import Logo from "./logo";
import VerificationInput from "react-verification-input";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import "./styles.css";
const steps = {
  yearOfBirth: 0,
  zipCode: 1,
  nextStep: 2,
};

const ApplicantVerification = ({
  onContinue = () => {},
  errorMessage = null,
  reset = 0,
}) => {
  const intl = useIntl();

  const yearOfBirthRef = useRef(null);
  const zipcodeRef = useRef(null);
  const submitRef = useRef(null);
  const yearOfBirthNodeRef = useRef(null);
  const zipCodeNodeRef = useRef(null);
  const continueNodeRef = useRef(null);

  const [innerReset, setInnerSet] = useState(0);
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const [formData, setFormData] = useState({
    yearOfBirth: undefined,
    zipCode: undefined,
  });
  const [step, setStep] = useState(steps.yearOfBirth);
  const [formDataValidation, setFormDataValidation] = useState(
    createValidationObject(formData)
  );

  const getIntlMessage = useCallback(
    (id) => intl.formatMessage({ id }),
    [intl]
  );

  const onYearOfDateCompleted = (value) => {
    const v = value || formData.yearOfBirth;
    const temp =
      !!v &&
      new Date().getFullYear() - parseInt(v) <= 100 &&
      new Date().getFullYear() - parseInt(v) >= 18;
    const isValid = temp;
    const tempResult = {
      ...formDataValidation,
      yearOfBirth: temp,
    };
    setFormDataValidation(tempResult);
    if (isValid) {
      setStep(steps.zipCode);
    }
  };

  const onZipcodeCompleted = (value) => {
    const v = value || formData.zipCode;
    const temp = !!v && v.match(/^\d{5}$/);
    const isValid = temp;
    const tempResult = {
      ...formDataValidation,
      zipCode: temp,
    };
    setFormDataValidation(tempResult);
    if (isValid) {
      setStep(steps.nextStep);
    }
  };

  const validateAll = () => {
    let isValid = true;
    let temp = true;
    let tempResult = _.cloneDeep(formDataValidation);
    Object.keys(formData).forEach((name) => {
      switch (name) {
        case "yearOfBirth":
          temp =
            !!formData.yearOfBirth &&
            new Date().getFullYear() - parseInt(formData.yearOfBirth) <= 100 &&
            new Date().getFullYear() - parseInt(formData.yearOfBirth) >= 18;
          isValid = isValid && temp;
          tempResult = {
            ...tempResult,
            yearOfBirth: temp,
          };
          break;
        case "zipCode":
          temp = !!formData.zipCode && formData.zipCode.match(/^\d{5}$/);
          isValid = isValid && temp;
          tempResult = {
            ...tempResult,
            zipCode: temp,
          };
          break;
      }
    });
    setFormDataValidation(tempResult);
    return isValid;
  };

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormDataValidation({
      ...formDataValidation,
      [name]: true,
    });
  };

  useEffect(() => {
    const temp = {
      ...formData,
      yearOfBirth: undefined,
      zipCode: undefined,
    };
    setFormData(temp);
    setFormDataValidation(createValidationObject(temp));
    setStep(steps.yearOfBirth);
  }, [reset, innerReset]);

  useEffect(() => {
    switch (step) {
      case steps.yearOfBirth:
        if (yearOfBirthRef.current) {
          yearOfBirthRef.current.focus();
        }
        break;
      case steps.zipCode:
        if (zipcodeRef.current) {
          zipcodeRef.current.focus();
        }
        break;
      case steps.nextStep:
        if (submitRef.current) {
          submitRef.current.focus();
        }
        break;
    }
  }, [step]);

  return (
    <div className={`${styles["verification-container"]}`}>
      <Logo />
      <div className='text-center t_welcome'>
        <FormattedMessage id='WelcomeToDentirate' />
      </div>
      {!!errorMessage && typeof errorMessage === "string" && (
        <Alert className='mt-4' variant='danger'>
          {errorMessage || getIntlMessage("Errors.InvalidLink")}
        </Alert>
      )}
      <form
        className='form mt-4 ml-4'
        onSubmit={(e) => {
          e.preventDefault();
          if (!validateAll() || step !== steps.nextStep) {
            return;
          }
          document.querySelectorAll("input").forEach((input) => input.blur());
          onContinue({
            zipCode: formData.zipCode,
            yearOfBirth: formData.yearOfBirth,
          });
        }}
      >
        <Row>
          {step === steps.yearOfBirth && (
            <Col lg={12} sm={12} ref={yearOfBirthNodeRef}>
              <ValidationWrapper
                errorMessage={
                  <FormattedMessage id='Errors.Validation.YearOfBirth' />
                }
                title={""}
                inputId='txt-year'
                isValid={formDataValidation.yearOfBirth}
              >
                <div className={`text-center ${styles["large-input-label"]}`}>
                  <FormattedMessage id='YearOfBirth' />
                </div>
                <VerificationInput
                  validChars='0-9'
                  onChange={(v) => handleChange("yearOfBirth", v)}
                  classNames={{
                    container: styles["veification-input-container"],
                  }}
                  value={formData.yearOfBirth || ""}
                  placeholder='_'
                  length={4}
                  onComplete={onYearOfDateCompleted}
                  autoFocus
                  inputProps={{ inputMode: "numeric" }}
                  ref={yearOfBirthRef}
                />
              </ValidationWrapper>
              {!!formData.yearOfBirth &&
                formData.yearOfBirth.toString().length === 4 && (
                  <Col>
                    <Button
                      variant='primary'
                      className={`w_100`}
                      type='button'
                      onClick={() =>
                        onYearOfDateCompleted(formData.yearOfBirth)
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </Col>
                )}
            </Col>
          )}
          {step === steps.zipCode && (
            <Col lg={12} sm={12} ref={zipCodeNodeRef}>
              <ValidationWrapper
                errorMessage={
                  <FormattedMessage id='Errors.Validation.ZipCode' />
                }
                title={""}
                inputId='txt-zipcode'
                isValid={formDataValidation.zipCode}
              >
                <div className={`text-center ${styles["large-input-label"]}`}>
                  <FormattedMessage id='ZipCode' />
                </div>
                <VerificationInput
                  validChars='0-9'
                  onChange={(v) => handleChange("zipCode", v)}
                  classNames={{
                    container: styles["veification-input-container"],
                  }}
                  autoFocus
                  value={formData.zipCode || ""}
                  placeholder='_'
                  length={5}
                  onComplete={(e) => {
                    setStep(steps.nextStep);
                  }}
                  inputProps={{ inputMode: "numeric" }}
                  ref={zipcodeRef}
                />
              </ValidationWrapper>

              <Row>
                <Col xs={6}>
                  <Button
                    variant='dark'
                    className={`w_100`}
                    type='button'
                    onClick={() => {
                      setStep(steps.yearOfBirth);
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>
                </Col>
                <Col xs={6}>
                  {!!formData.zipCode &&
                    formData.zipCode.toString().length === 5 &&
                    formDataValidation.zipCode && (
                      <Button
                        variant='primary'
                        className={`w_100`}
                        type='button'
                        onClick={() => onZipcodeCompleted(formData.zipCode)}
                      >
                        <FontAwesomeIcon icon={faArrowRight} /> Next
                      </Button>
                    )}
                </Col>
              </Row>
            </Col>
          )}
          {step === steps.nextStep && (
            <div ref={continueNodeRef}>
              {/* <Col className={`service ${styles["agree-terms-service"]}`}>
                <span>
                  <FormattedMessage id='ContinuingAgreement.Part1' />
                </span>
                <a className='link_primary fw_bold' href='/terms-of-service'>
                  <FormattedMessage id='ContinuingAgreement.Part2' />
                </a>
              </Col> */}
              <Row className={styles["inital-info-summary"]}>
                <Col xs={6} className='t_right'>
                  <span className={styles["column-label"]}>
                    <FormattedMessage id='YearOfBirth' />
                  </span>
                </Col>
                <Col xs={6} className={``}>
                  <span className={styles["column-value"]}>
                    {formData.yearOfBirth}
                  </span>
                </Col>
              </Row>
              <Row className={styles["inital-info-summary"]}>
                <Col xs={6} className='t_right'>
                  <span className={styles["column-label"]}>
                    <FormattedMessage id='ZipCode' />
                  </span>
                </Col>
                <Col xs={6} className={``}>
                  <span className={styles["column-value"]}>
                    {formData.zipCode}
                  </span>
                </Col>
              </Row>

              <Col>
                <Row>
                  <Col xs={6}>
                    <Button
                      variant='dark'
                      className={`mt_3 w_100`}
                      type='button'
                      onClick={() => {
                        setStep(steps.zipCode);
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button
                      variant='primary'
                      className={`mt_3 w_100`}
                      type='submit'
                      ref={submitRef}
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </div>
          )}
        </Row>
      </form>
    </div>
  );
};

ApplicantVerification.propTypes = {
  onSuccessfulVerification: PropTypes.func,
};

export default ApplicantVerification;
